import { addDays } from "date-fns";
import { Blog } from "~/types/api/blog.types";
import { useGeneralStore } from "~/stores/general";
import { getProductImages, removeHTMLTagFromString } from "~/utils";
import { CatalogData } from "~/types/api/catalog.types";
import { ProductData } from "~/types/api/product.types";

export const useJsonLdSchema = () => {
  interface getBaseSchemaPayloadI {
    name?: string;
    image?: string;
  }
  interface schemaPageData {
    name?: string;
    image?: string;
  }

  interface imagesData {
    url: string;
    alt?: string;
    type: string;
  }

  const generalStore = useGeneralStore();
  const route = useRoute();
  const config = useRuntimeConfig();
  const { t } = useI18n();
  const localePath = useLocalePathPolyfill();

  const frontDomain = generalStore.bootstrap?.settings?.front_domain;
  const cleanFrontDomain =
    frontDomain && frontDomain?.trim()?.endsWith("/")
      ? frontDomain.slice(0, -1)
      : frontDomain;
  const baseUrl = cleanFrontDomain || config.public.domain || "";
  const url = `${baseUrl}${route.path}`;

  function getBaseSchemaPayload({ name, image }: getBaseSchemaPayloadI) {
    const obj = {
      "@context": "https://schema.org",
      "@type": "WebPage",
      name: name || generalStore.settings?.company_name, // must be page title by default
      url,
      image: image || generalStore.settings?.desktop_logo || "",
    };
    return obj as any;
  }
  function getSchemaBreadcrumbs() {
    return {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: generalStore.breadcrumbs?.map((el, idx) => ({
        "@type": "ListItem",
        position: idx + 1,
        item: {
          "@type": "WebPage",
          "@id": `${baseUrl}${localePath(el.href === "/" ? "" : el?.href || ("" as string))}`,
          name: el.text,
          url: `${baseUrl}${localePath(el.href === "/" ? "" : el?.href || ("" as string))}`,
        },
      })),
    };
  }

  function setHomePageJsonLdSchema({ name, image }: schemaPageData) {
    const payload = {
      ...getBaseSchemaPayload({ name, image }),
      provider: {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: generalStore.settings?.company_address?.email_address,
        telephone: generalStore.settings?.company_address?.phone_number,
        openingHours: generalStore.settings?.working_hours,
        name: generalStore.settings?.company_name,
        image: generalStore.settings?.desktop_logo,
      },
    };
    if (generalStore.settings?.company_address) {
      payload.provider.address = {
        "@type": "PostalAddress",
        streetAddress:
          (generalStore.settings?.company_address?.address_line_1 || "") +
          (generalStore.settings?.company_address?.address_line_2
            ? ", " + generalStore.settings?.company_address?.address_line_2
            : ""),
        addressLocality: generalStore.settings?.company_address?.city,
        addressRegion: generalStore.settings?.company_address?.state,
        addressCountry: generalStore.settings?.company_address?.country,
        postalCode: generalStore.settings?.company_address?.zip,
      };
    }
    useJsonld(payload);
  }
  function setPageJsonLdSchema({ name, image }: schemaPageData) {
    useJsonld({
      ...getBaseSchemaPayload({ name, image }),
      breadcrumb: getSchemaBreadcrumbs(),
    });
  }

  function getBaseBlogSchemaPayload(
    post: Blog,
    options: {
      id?: string;
      url?: string;
      imagePosition: string;
      mainEntity?: boolean;
    } = { imagePosition: "top" },
  ) {
    const postUrl =
      options?.id ||
      `${baseUrl}${localePath(`/blog/${post?.categories?.[0]?.slug || "all"}/${post.slug}`)}`;
    return {
      "@type": options?.mainEntity ? "Article" : "BlogPosting",
      "@id": postUrl,
      mainEntityOfPage: options?.mainEntity
        ? {
            "@type": "WebPage",
            "@id": postUrl,
          }
        : null,
      headline: post?.currentTranslation?.title || "",
      description: removeHTMLTagFromString(
        post?.currentTranslation?.meta_description ||
          post?.currentTranslation?.short_description ||
          "",
      ),
      publisher: {
        "@type": "Organization",
        name: generalStore.settings?.company_name,
        logo: generalStore.settings?.desktop_logo,
      },

      datePublished: post.created_at,
      dateModified: post.updated_at,
      // author: { // TODO define who is author
      //   "@type": "Person",
      //   "@id": "https://dataliberate.com/author/richard-wallis/#Person",
      //   name: "Richard Wallis",
      // },
      image:
        getImageByPosition(post.medias, options.imagePosition)?.file?.url ||
        "/images/no-image.webp",
    };
  }

  function setBlogListPageLdSchema({
    name,
    posts,
  }: {
    name: Ref<string>;
    posts: Ref<Blog[]>;
  }) {
    useJsonld(() => ({
      ...getBaseSchemaPayload({ name: name.value }),
      breadcrumb: getSchemaBreadcrumbs(),
      mainEntity: {
        "@context": "https://schema.org",
        "@type": "Blog",
        image: generalStore.settings?.desktop_logo || "",
        name: name.value,
        url: `${baseUrl}${route.path}`,

        "@id": `${baseUrl}${route.path}`,
        mainEntityOfPage: `${baseUrl}${route.path}`,
        blogPost: posts.value?.map((post) => ({
          ...getBaseBlogSchemaPayload(post, { imagePosition: "list" }),
        })),
      },
    }));
  }

  function setBlogPostPageLdSchema({
    name,
    image,
    post,
  }: schemaPageData & { post: Ref<Blog> }) {
    const breadcrumbs = getSchemaBreadcrumbs();
    useJsonld({
      ...getBaseSchemaPayload({ name, image }),
      breadcrumb: breadcrumbs,
      mainEntity: {
        ...getBaseBlogSchemaPayload(post.value, {
          id: url,
          url,
          imagePosition: "page",
          mainEntity: true,
        }),
        isPartOf: {
          "@type": "Blog",
          "@id": `${baseUrl}${localePath("/blog")}`,
          name: t("blog"),
          publisher: {
            "@type": "Organization",
            "@id": baseUrl,
            name: generalStore.settings?.company_name,
          },
        },
      },
    });
  }

  function getBaseProductSchemaPayload(
    product: CatalogData | ProductData,
    options?: {
      productUrl?: string;
      title?: string;
      price?: number;
      image?: string;
      sku?: string;
      description?: string;
    },
  ) {
    const productTitle =
      // configurationInfo.value?.currentTranslation?.title ||
      // dynamicProductTitle.value ||
      options?.title ||
      (product as CatalogData)?.variant_plp_image?.currentTranslation?.title ||
      (product as CatalogData)?.default_config?.currentTranslation?.title ||
      (product as CatalogData)?.currentTranslation?.title ||
      "";

    const productDescription =
      options?.description ||
      (product as CatalogData)?.variant_plp_image?.currentTranslation
        ?.description ||
      (product as CatalogData)?.default_config?.currentTranslation
        ?.description ||
      (product as ProductData)?.currentTranslation?.long_description ||
      "";

    const productPrice = Number(
      options?.price ||
        (product as CatalogData)?.variant_plp_image?.cost ||
        product?.default_config?.cost ||
        product?.base_cost ||
        0,
    );

    const discountValue = (() => {
      if (!product?.sale || !product?.sale_value || !product) {
        return 0;
      }

      return Number(
        (productPrice - productPrice * (+product.sale_value / 100)).toFixed(2),
      );
    })();

    const productImageUrl =
      options?.image ||
      getProductImages(product as CatalogData)?.[0]?.url ||
      "/images/no-image.webp";

    const productSKU =
      options?.sku ||
      (product as CatalogData)?.variant_plp_image?.sku ||
      product?.default_config?.configuration_id ||
      product?.default_sku;

    const finalPrice = discountValue || productPrice || 0;
    return {
      "@type": "Product",
      image: productImageUrl,
      url:
        options?.productUrl ||
        `${baseUrl}${localePath(`/product/${product?.slug}`)}`,
      name: productTitle,
      description: removeHTMLTagFromString(productDescription),
      sku: productSKU,
      brand: generalStore.settings?.company_name,

      offers: {
        "@type": "Offer",
        priceCurrency: generalStore.bootstrap?.settings?.currency_code || "",
        price: finalPrice.toFixed(2),
        priceValidUntil: addDays(new Date(), 30),
        availability: finalPrice
          ? "https://schema.org/InStock"
          : "https://schema.org/OutOfStock",
        url:
          options?.productUrl ||
          `${baseUrl}${localePath(`/product/${product?.slug}`)}`,
      },
    };
  }

  function setPLPPageLdSchema({
    name,
    image,
    numberOfItems,
    products,
  }: {
    name: string;
    image: string;
    numberOfItems: Ref<number>;
    products: Ref<CatalogData[]>;
  }) {
    useJsonld(() => ({
      ...getBaseSchemaPayload({ name, image }),
      breadcrumb: getSchemaBreadcrumbs(),
      mainEntity: {
        "@context": "https://schema.org",
        "@type": "ItemList",
        url: `${baseUrl}${route.fullPath}`,
        numberOfItems: numberOfItems.value,
        itemListElement: products.value?.map((product) =>
          getBaseProductSchemaPayload(product),
        ),
      },
    }));
  }

  function setProductPageLdSchema({
    name,
    images,
    product,
    price,
    sku,
    description,
  }: {
    name: Ref<string>;
    images: Ref<imagesData[]>;
    product: Ref<ProductData>;
    price: Ref<number>;
    sku: string;
    description: Ref<string>;
  }) {
    useJsonld(() => ({
      ...getBaseSchemaPayload({
        name: name.value,
        image: images.value?.[0]?.url,
      }),
      breadcrumb: getSchemaBreadcrumbs(),
      mainEntity: {
        "@context": "https://schema.org",
        ...getBaseProductSchemaPayload(product.value, {
          title: name.value,
          price: price.value,
          productUrl: `${baseUrl}${route.fullPath}`,
          image: images.value?.[0]?.url,
          sku,
          description: description.value,
        }),
      },
    }));
  }

  return {
    setHomePageJsonLdSchema,
    setPageJsonLdSchema,
    setBlogListPageLdSchema,
    setBlogPostPageLdSchema,
    setPLPPageLdSchema,
    setProductPageLdSchema,
  };
};
